// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text_text__MzWYW {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

div.Text_text__MzWYW > p {
    margin: 0;
    color: #FBFBFB;
}

.Text_pReduceLineSpace__EmqPa, .Text_pReduceLineSpace__EmqPa > p {
    flex-grow: 1;
    margin: 0
}

.Text_removeMarginTop__rndji > h2 {
    margin-top: 0;
}

.Text_removeMarginBottom__\\+P6Y\\+ > h2 {
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Text/Text.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".text {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    justify-content: center;\n    align-items: center;\n}\n\ndiv.text > p {\n    margin: 0;\n    color: #FBFBFB;\n}\n\n.pReduceLineSpace, .pReduceLineSpace > p {\n    flex-grow: 1;\n    margin: 0\n}\n\n.removeMarginTop > h2 {\n    margin-top: 0;\n}\n\n.removeMarginBottom > h2 {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Text_text__MzWYW`,
	"pReduceLineSpace": `Text_pReduceLineSpace__EmqPa`,
	"removeMarginTop": `Text_removeMarginTop__rndji`,
	"removeMarginBottom": `Text_removeMarginBottom__+P6Y+`
};
export default ___CSS_LOADER_EXPORT___;
