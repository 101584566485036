// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SSIIdentityVerificationPage_caption__B-ssK {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.SSIIdentityVerificationPage_rightPanel__zFo47 {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
}

.SSIIdentityVerificationPage_contentContainer__URj5x {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.SSIIdentityVerificationPage_identityContainer__urP0N {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.SSIIdentityVerificationPage_identityInformationContainer__iqwG3 {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.SSIIdentityVerificationPage_identityInformationCaption__fhnQa {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.SSIIdentityVerificationPage_identityInformationFieldsContainer__lAUe8 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SSIIdentityVerificationPage/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".caption {\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 32px;\n}\n\n.rightPanel {\n    display: flex;\n    flex-grow: 1;\n    align-items: center;\n    flex-direction: column;\n}\n\n.contentContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n}\n\n.identityContainer {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n}\n\n.identityInformationContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.identityInformationCaption {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n}\n\n.identityInformationFieldsContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": `SSIIdentityVerificationPage_caption__B-ssK`,
	"rightPanel": `SSIIdentityVerificationPage_rightPanel__zFo47`,
	"contentContainer": `SSIIdentityVerificationPage_contentContainer__URj5x`,
	"identityContainer": `SSIIdentityVerificationPage_identityContainer__urP0N`,
	"identityInformationContainer": `SSIIdentityVerificationPage_identityInformationContainer__iqwG3`,
	"identityInformationCaption": `SSIIdentityVerificationPage_identityInformationCaption__fhnQa`,
	"identityInformationFieldsContainer": `SSIIdentityVerificationPage_identityInformationFieldsContainer__lAUe8`
};
export default ___CSS_LOADER_EXPORT___;
